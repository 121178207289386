@tailwind base;
@tailwind components;
@tailwind utilities;
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.autocomplete-input-container {
  position: relative;
}

/* Style the input field */
.autocomplete-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Style each autocomplete suggestion */
.autocomplete-suggestion {
  padding: 5px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
}

/* Highlight the selected suggestion */
.autocomplete-suggestion.active {
  background-color: #f0f0f0;
}

/* Style the autocomplete dropdown container */
.autocomplete-dropdown-container {
  position: absolute;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  border: 1px solid #ccc;
  background-color: #000;
  z-index: 10;
}