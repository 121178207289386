.map-container {
    height: 400px;
  }
  
  .map-col {
    padding: 0;
  }
  
  .map {
    height: 100%;
    background-color: lightgray; 
    max-height: 50vh;
  }
  
  .info-col {
    background-color: #f7f7f7;
  }
  
  .info-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .rectangle-box {
    background-color: green;
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .green-input {
    background-color: green;
    border: none;
    margin: 5px;
    padding: 5px;
    color: white;
  }
  