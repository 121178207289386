.btn-hovers {
  /* Default button color */
  background-color: green;
  border-color: green;

  /* Button color on hover */
  &:hover {
    background-color: #0056b3;
  }
}
