.navbar {
    /* position: fixed; */
    /* position: absolute; */
    /* box-shadow: 1px 2px 2px #e5e5e5; */
    font-size: 20px !important;
    z-index: 2;
    align-items: center;
    background-color: #000000;
    justify-content: space-between;
    /* position: fixed !important; */
    width: 100%;
    /* padding: 1.5rem 1rem !important; */
  }
  .navbar-collapse {
    flex-grow: unset !important;
  }
  #navbarScroll .navbar-nav {
    gap: 25px;
    align-items: center;
  }
  #botone .nav-link:hover {
    background-color: #000000;
    text-decoration: 3px solid underline #06854A;
    color: #fff;
    font-weight: 500;
  }
  #botone .dropdown-menu .dropdown-item:hover {
    text-decoration: underline #3bb44a;
    color: #1e75bb;
    font-weight: 500;
  }
  .navbar-brand img {
    object-fit: cover;
    width: 70%;
  }
  .btnsignup{
    background-color: #000000;
    border: none;
    color: aliceblue;
  }
  .btnlogin{
    border: none;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    background-color: #06854A;
    border-top-left-radius: 50px;
    color: white;
    /* font-size: larger; */
    padding-left: 40px;
    border-bottom-left-radius: 50px;
  }
  .nnnnav {
    margin-left: 10px;
  }
  .nnnnav .nav-link {
    text-align: center;
    background: none !important;
    color: rgb(255, 255, 255) !important;
    border-radius: 40px;
    padding: 10px;
  }
  .nnnnav .dropdown-menu .dropdown-item:hover {
    background: none !important;
  }
  /* .nav-link:focus{
      color: white !important;
  } */
  .nnnnav .dropdown-menu a:hover {
    color: #39bec1 !important;
    background: none !important;
  }
  .nnnnav .dropdown-menu a {
    color: black !important;
  }
  .nnnnav .dropdown-menu {
    text-align: center;
    background: #ffffff;
    /* margin-left: -71px; */
  }
  .navbar-brand {
    width: 20%;
    line-height: 22px;
    text-align: -webkit-right;
    /* font-size: 25px; */
    color: #000000 !important;
    font-weight: bold;
  }
  .navbar-dark .navbar-nav .nav-link {
    padding-left: 16px;
    font-size: 16px;
    color: white !important;
  }
  /* .bg-dark {
      background-color: #343a4091 !important;
  } */
  /* @media only screen and (min-width: 768px) {
      .navbar-expand-lg .navbar-nav .nav-link{
          font-size: 15px;
      }
  } */
  .navbar-expand-lg .navbar-nav .nav-link {
    color: white;
  }
  .navbar-toggler {
    background-color: white;
  }
  .icon a svg {
    font-size: 20px;
    color: black;
  }
  .icon a {
    padding: 7px;
    background: white;
    border-radius: 43px;
    color: #ffffff00 !important;
  }
  @media only screen and (min-width: 600px) {
    .navbar-expand-lg .navbar-nav .nav-link {
      font-size: 16px;
      padding: 0px;
    }
  }
  @media only screen and (max-width: 992px) {
    .btnlogin{
        background: none;
        padding-top: 0rem;
    padding-bottom: 0rem;
    padding-right: 0rem;
    padding-left: 0rem;
    }
      .navbar-brand img{
          width: 100%;
      }
    .navbar-collapse {
      background-color: rgb(0 0 0 / 86%);
      /* border-radius: 20px; */
      padding: 20px;
    }
    #navbarScroll .navbar-nav {
      align-items: flex-start;
    }
  
    .navbar-dark .navbar-nav .nav-link {
      color: white !important;
    }
    .navbar-brand {
      width: 35%;
      color: white !important;
      font-size: 20px;
    }
    /* .bg-dark{
        background-color: #343a40 !important;
      } */
  }
  .navbar-dark .navbar-nav .nav-link a:hover {
    color: #001d35 !important;
  }
  
  .navbar.colorChange {
    background-color: rgba(0, 50, 70, 0.956) !important;
  }
  .navbar.colorChange .navbar-nav .nav-link {
    color: white !important;
  }
  .navbar.colorChange .navbar-brand {
    color: white !important;
    text-align: center;
  }
  
  .btn-login {
    color: #414042;
    background: none;
    border: 1px solid #414042;
  }
  .btn-signup {
    color: white;
    background-color: #1e75bb;
  }
  .btn-login:hover {
    background: none;
    color: #414042;
    border: 1px solid #414042;
  }
  .btn-signup:hover {
    background-color: #1e75bb;
  }
  