.container {
    padding: 20px;
  }
  
  .booking-card {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    margin: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .booking-card img {
    max-width: 100%;
    height: auto;
  }

  .btn-hover {
    /* Default button color */
    background-color: green;
    border: "none";
    
  
    /* Button color on hover */
    &:hover {
      background-color: #0056b3;
    }
}
  
  .booking-card .title {
    font-size: 18px;
    color: #333;
  }
  
  .booking-card .details-button {
    margin-top: 8px;
  }