@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #edeef0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.bgImage {
  /* z-index: 1; */
  position: relative;
  background-image: url(Components/assets/homecar.png);
  object-fit: cover;
  background-repeat: round;
  
  /* height: 500px; */
}
.bg-tour {
  z-index: 1;
  position: relative;
  background-image: url(Components/assets/bgtour.png);
  object-fit: cover;
  background-repeat: round;
  flex-direction: row;
  padding-top: 250px;
  display: flex;
  align-items: self-end;
}
.ATS {
  z-index: 1;
  position: relative;
  background: url(Components/assets/road.png) #1e1e1e;
  object-fit: cover;
  background-repeat: round;
  /* height: 650px; */
}
.webkit {
  text-align: -webkit-center;
}
.contactForm {
  background: linear-gradient(89deg, black, #858585);
  border-radius: 30px;
  padding: 15px;
}
.tablist {
  border: 2px solid black !important;
  display: block;
}
.chakra-tabs__tablist {
  display: contents !important;
}
.chakra-tabs__tab {
  width: 100%;
  border-bottom: 2px solid black !important;
  justify-content: left !important;
  color: #00803e !important;
  font-weight: 700;
  border-radius: 0px !important;
  margin-top: 10px;
}
.startnew {
  color: #fac505 !important;
}
.colorGreen {
  color: #00803e;
}
.bgGreen {
  background-color: #00803e;
}

.About_main_images_search {
  /* width: 500px; */
  background-color: #ffffff;
  height: 50px;
  border-radius: 5px;
  margin-top: 20px;
  gap: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  border: 1px solid black;
}
:focus-visible {
  outline: white;
}
select {
  color: #808080 !important;
}
.About_main_images_search input {
  width: 100%;
  border: none;
  color: black;
  padding: 10px;
  font-family: ui-rounded;
  border-radius: 4px 4px 4px 4px;
}
.About_main_images_search button {
  border: 1px solid gray;
  /* background-color: gray; */
  color: white;
  /* border-radius: 0px 4px 4px 0px; */
  /* width: 15%; */
}

.About_main_images_search p {
  font-size: 22px;
}
#mm1 .swiper-button-prev {
  padding: 23px;
  left: 90%;
  background: #000000;
  /* left: 45% !important; */
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
#mm1 .swiper-button-next,
#mm1 .swiper-button-prev {
  top: 34px;
}
/* #paymentmodal .modal-content{

box-shadow: 0px 0px 5px 3px #1c1c1c !important;
} */
/* .MuiTabs-indicator {
display: none;
} */
#mm1 .swiper-button-next {
  padding: 23px;
  background: #000000;
  /* left: 45% !important; */
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  /* right: 45%; */
}
#mm1 .swiper-button-next:after,
#mm1 .swiper-button-prev:after {
  font-size: 27px !important;
  font-weight: bold;
  color: white;
}
#mm1 .swiper-wrapper {
  padding-top: 65px;
  padding-bottom: 60px;
}
.widthres {
  width: 80%;
  height: 13rem;
}
.logoFooter {
  width: 80%;
}
.widthres h2 {
  font-size: 40px;
  font-weight: 800;
}
.widthres p {
  font-size: 20px;
  font-weight: 800;
}
.text-footer {
  font-size: 18px;
}
.hr {
  /* -webkit-transform:rotate(180deg); */
  border: 2px solid white;
  width: 100%;
}
.rrr .col {
  border-left: 2px solid gray;
}
.rrr .col:nth-child(1) {
  border-left: none;
}
#mm1 .swiper-pagination-bullet-active {
  background-color: #00803e;
  padding: 7px;
}
.bgBlack {
  background-color: #1e1e1e;
}
.swiper-pagination-bullet {
  padding: 7px;
  background-color: #808080;
}
.form-control-new {
  background: none;
  width: 60%;
  color: white;
  border-left: 2px solid white;
  border-top: 2px solid;
  border-bottom: 2px solid;
  padding: 10px;
}
.btngreenform {
  width: 30%;
  background-color: #00803e;
  color: white;
  font-weight: 700;
  padding: 15px;
  border: 2px solid #00803e;
  text-align: center;
}
.dNoneRes {
  display: flex;
}
.resmar {
  margin-top: 20px;
}
.rrr .col h2 {
  display: grid;
}
@media only screen and (max-width: 992px) {
  #sideSS .react-tabs {
    flex-direction: column;
  }
  .logoFooter {
    width: auto;
  }
  .form-control-new {
    background: none;
    color: white;
    width: 100%;
    border-left: 2px solid white;
    border-top: 2px solid;
    border-bottom: 2px solid;
    border-right: 2px solid;
    padding: 10px;
    margin-top: 10px;
  }
  .btngreenform {
    width: 100%;
    margin-top: 10px;
    background-color: #00803e;
    color: white;
    font-weight: 700;
    padding: 10px;
    border: 2px solid #00803e;
    text-align: center;
  }
  .widthres h2 {
    font-size: 25px;
    font-weight: 800;
  }
  .dNoneRes {
    display: contents;
  }
  .widthres p {
    font-size: 15px;
    font-weight: 800;
  }
  .widthres {
    width: auto;
    height: 10rem;
  }

  .boxser {
    width: auto !important;
    height: 200px !important;
  }
  .pcol2 p {
    font-size: 16px;
  }

  /* #mm1 .swiper-button-next{
    right: 35% ;
} */

  #mm1 .swiper-button-prev {
    left: 67% !important;
  }
  .About_main_images_search {
    /* width: 340px; */
    background-color: #fff;
    height: 50px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
  }

  .bgImage {
    z-index: 1;
    /* height: 713px; */
    position: relative;
    background-image: url(Components/assets/bg.png);
  }
  .sec2 {
    margin-top: auto !important;
  }
  #navbarScroll .navbar-nav:nth-child(6) {
    align-items: end;
  }
  .oneway {
    margin-top: -40px !important;
  }
  .bgAS h2 {
    font-size: 25px !important;
  }
  .bgAS {
    height: auto !important;
    padding: 74px;
  }
  .bgRC h2 {
    font-size: 25px !important;
  }
  .bgTour {
    height: auto !important;
    padding: 74px;
  }
  .bgTour h2 {
    font-size: 25px !important;
  }
  .bgRC {
    height: auto !important;
    padding: 74px;
  }
  .box {
    padding: 8px !important;
  }
  .box p {
    font-size: 12px !important;
  }
  .bgRC p {
    margin-top: 20px;
    font-size: 12px !important;
  }
  .broa .text-lg {
    line-height: normal !important;
    font-size: 14px;
  }
}
.boxRecCar {
  height: 379px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: url(Components/assets/RecCar.png);
  background-repeat: round;
  background-size: 100% 100%;
}
.box p {
  font-size: 14px;
}
.box {
  padding: 30px;
}
.oneway .react-tabs__tab {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
}
.oneway .react-tabs__tab--selected {
  background: none;
  font-size: 20px;
  color: #1e1e1e;
  font-weight: 700;
  text-decoration: underline;
  border: none;
}
.oneway .react-tabs__tab-list {
  border-bottom: none;
}
.oneway .react-tabs__tab:focus:after {
  display: none;
}
.oneway {
  margin-top: -180px;
  background: #00803e;
  border-radius: 20px;
  padding: 20px;
}
.bgAS h2 {
  font-size: 60px;
}
.bgRC h2 {
  font-size: 60px;
}
.bgTour h2 {
  font-size: 60px;
}
.bgRC p {
  font-size: 20px;
}

.sec2 {
  background-color: #1e1e1e;
  margin-top: -50px;
  position: relative;
}
.boxser {
  border: 2px solid #fff;
  border-radius: 20px;
  height: 250px;
  width: 50%;
  text-align: -webkit-center;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
}
img {
  object-fit: cover;
  width: 100%;
}
.boxser img {
  width: 60%;
}
.bgAS {
  height: 600px;
  background: url(Components/assets/bgAS.png);
  background-repeat: round;
  display: flex;
  object-fit: contain;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.bgRC {
  height: 600px;
  background: url(Components/assets/bgRC.png);
  background-repeat: round;
  display: flex;
  object-fit: contain;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.bgTour {
  height: 600px;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(Components/assets/Flight.png);
  background-repeat: round;
  display: flex;
  object-fit: contain;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.login-form-group {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
  display: flex;
}

.login-title {
  padding: 15px;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
}

.login-form {
  /* width: 350px; */
  background-color: #edeef0;
  border-top-left-radius: 30px;
  /* padding: 30px; */
  border-bottom-right-radius: 30px;
  /* display: grid; */
  grid-template-columns: 1fr;
  row-gap: 16px;
  /* box-shadow: -10px -10px 6px 0px #00803e; */
}
.btn:hover {
  background: none;
}
.login-form label {
  display: block;
  margin-bottom: 8px;
}

.login-form input {
  width: 100%;
  padding: 1.2rem;
  border-radius: 9px;
  border: none;
}

.login-form input:focus {
  outline: none;
  box-shadow: 0 0 0 4px rgba(253, 242, 233, 0.5);
}
.bg-slate-200:hover {
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}
.btn--form {
  background-color: #00803e !important;
  color: #fff;
  align-self: end;
  padding: 8px;
}

.btn--form:hover {
  background-color: #fff !important;
  color: #00803e;
  border: 1px solid #00803e;
}

.box-login {
  background-color: white;
  padding: 10px;
  border-radius: 30px;
  box-shadow: 5px 5px 0px 0px gray;
}

/* new form */
#bot .nav-link {
  color: white;
  margin-left: 0.5rem;
  font-size: 16px;
  padding: 10px;
  text-align: -webkit-center;
  border-left: 1px solid white !important;
}
#bot {
  z-index: 1;
  background: #333333;
}
.css-19owhuc {
  background: none !important;
}
.css-1cgx7kx-MuiTabs-scroller {
  background-color: #e6e6e6;
}
.css-1cgx7kx-MuiTabs-scroller {
  width: 240px;
}
#sideSS {
  margin-top: 30px;
}
#sideSS .react-tabs {
  display: flex;
  color: black;
}
.clacla .react-tabs__tab {
  color: black;
  margin-top: 20px;
}
#sideSS .react-tabs__tab-list {
  display: flex;
  flex-direction: column;
  border-bottom: none;
  text-align: left;
  /* width: 170px; */
  margin: 0;
  /* height: 865px; */
  padding: 0;
  color: white;
}
.clacla {
  margin-block-end: auto;
  padding: 10px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 5px 1px #8e8e8e;
  background: #fff;
}

@media screen and (max-width: 480px) {
  .smallScreenText {
    font-size: 12px !important;
  }

  .smallScreenHeading {
    font-size: 24px !important;
  }
  .alignleft {
    text-align: left !important;
  }
  .mediumText {
    font-size: 12px !important ;
  }

  .fourteen {
    font-size: 14px !important ;
  }

  .averageText {
    font-size: 16px !important;
  }

  .extraSmall {
    font-size: 10px !important ;
  }
  .largeText {
    font-size: 18px !important ;
  }

  .flexWrap {
    flex-wrap: wrap !important;
  }

  .fullwidth {
    width: 100% !important;
  }
  .halfwidth {
    width: 50% !important;
  }
  .aligncenter {
    text-align: center !important ;
  }
  .marginTop {
    margin-top: 20px !important;
  }

  .marginTopTen {
    margin-top: 10px !important;
  }
  .marginBottomTen {
    margin-bottom: 10px !important;
  }

  #mm1 .swiper-button-prev {
    /* margin-right: 60px !important; */
    left: 50% !important;
  }
  .zeroMarginLeft {
    margin-left: 0px !important;
  }

  .zeroMarginRight {
    margin-right: 0px !important;
  }
  .zeroMarginTop {
    margin-top: 0px !important ;
  }
  .zeroMarginBottom {
    margin-bottom: 0px !important;
  }
  .paddingZaro {
    padding: 0px !important;
  }
  .height250 {
    height: 200px !important;
  }
}

@media screen and (max-width: 576px) {
  .heigth350 {
    height: 500px !important;
  }
  .heigthTable {
    height: 500px !important;
  }
  .marginTable {
    margin-top: 300px !important;
  }
  .marginTableCar {
    margin-top: 400px !important;
  }
  .marginTop {
    margin-top: 20px !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .smallText {
    font-size: 12px !important ;
  }
  .flexWrap {
    flex-wrap: wrap;
  }

  .largeScreenFullWidth {
    width: 100% !important;
  }

  .mediumText {
    font-size: 16px !important ;
  }

  .largeText {
    font-size: 20px !important ;
  }

  #mm1 .swiper-button-prev {
    /* margin-right: 60px !important; */
    left: 80% !important;
  }

  .xlText {
    font-size: 28px !important ;
  }
  .marginTop {
    margin-top: 1px;
  }
}

@media screen and (min-width: 480px) and (max-width: 950px) {
  .heigth350 {
    height: 500px !important;
  }
}

@media screen and (min-width: 800px) and (max-width: 1280px) {
  .LargeScreensmallText {
    font-size: 12px !important ;
  }

  .LargeScreenmediumText {
    font-size: 14px !important ;
  }

  .LargeScreenlargeText {
    font-size: 20px !important ;
  }
  #mm1 .swiper-button-prev {
    /* margin-right: 60px !important; */
    left: 80% !important;
  }

  .LargeScreenxlText {
    font-size: 28px !important ;
  }
}
